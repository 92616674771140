html {
  font-size: 62.5%;
}

body {
  width: 100%;
  font-size: 1.6rem;
  min-height: 100vh;
  box-sizing: border-box;
  overflow-x: hidden;
}

.leaflet-container {
  width: 100%;
  height: 50vh;
  // color: rgb(255, 165, 0);
  // color: rgb(255, 0, 0);
}
